












































import { inject } from '@nuxtjs/composition-api';
import { IOnboardingInput } from '~/types/app/Location';
import { DEFAULT_ONBOARDING_INPUT } from '~/components/onboarding/LocationSelection/provide';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  heading: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    geocodedAddress,
    deliveryEta,
    addressAddition
  } = inject<IOnboardingInput>('onboardingInput', DEFAULT_ONBOARDING_INPUT);
  return {
    emit,
    geocodedAddress,
    deliveryEta,
    addressAddition
  };
};

export default __sfc_main;
