import { render, staticRenderFns } from "./LocationSelectionHub.vue?vue&type=template&id=a8f54f32&scoped=true&"
import script from "./LocationSelectionHub.vue?vue&type=script&lang=ts&"
export * from "./LocationSelectionHub.vue?vue&type=script&lang=ts&"
import style0 from "./LocationSelectionHub.vue?vue&type=style&index=0&id=a8f54f32&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8f54f32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationSelectionHubSuccess: require('/app/build/components/onboarding/LocationSelection/LocationSelectionHubSuccess.vue').default,LocationSelectionHubFailure: require('/app/build/components/onboarding/LocationSelection/LocationSelectionHubFailure.vue').default})
