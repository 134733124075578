














import { computed, inject, useContext, useRoute } from '@nuxtjs/composition-api';
import { IOnboardingInput, IZoomInfo } from '~/types/app/Location';
import { DEFAULT_ONBOARDING_INPUT } from '~/components/onboarding/LocationSelection/provide';
import { MAP_IDS } from '~/lib/constants';
import { EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.setup = (__props, __ctx) => {
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute();
  const emit = __ctx.emit;
  const {
    coordinates
  } = inject<IOnboardingInput>('onboardingInput', DEFAULT_ONBOARDING_INPUT);
  const zoomInfo = inject<IZoomInfo>('zoomInfo');
  const mapOptions = computed<google.maps.MapOptions>(() => {
    const defaultOptions = {
      mapId: MAP_IDS.locationMap
    };
    return zoomInfo ? {
      zoom: zoomInfo.currentZoom.value,
      ...defaultOptions
    } : defaultOptions;
  });

  function onSignup(email: string): void {
    trackSignupSelected();
    emit('waitlist-signup', email);
  }

  function trackSignupSelected(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.waitlistSignupSelected,
      screenName: EScreenName.outsideDeliveryArea,
      componentContent: `${coordinates.value?.latitude}|${coordinates.value?.longitude}`,
      eventOrigin: getScreenName(route.value)
    });
  }

  return {
    emit,
    coordinates,
    mapOptions,
    onSignup
  };
};

export default __sfc_main;
