import { render, staticRenderFns } from "./WaitlistSignupForm.vue?vue&type=template&id=0c957f68&scoped=true&"
import script from "./WaitlistSignupForm.vue?vue&type=script&lang=ts&"
export * from "./WaitlistSignupForm.vue?vue&type=script&lang=ts&"
import style0 from "./WaitlistSignupForm.vue?vue&type=style&index=0&id=0c957f68&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c957f68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VTextField: require('/app/build/components/VTextField/VTextField.vue').default,Button: require('/app/build/components/Button/Button.vue').default})
