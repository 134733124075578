
















import { watch, inject, onMounted, useContext } from '@nuxtjs/composition-api';
import { getDeliveryEstimate } from '~/api/services/get-delivery-estimate';
import { EScreenName, ETrackingType } from '~/lib/segment';
import { defineEmits } from '@vue/runtime-dom';
import getHub from '~/api/services/get-hub/get-hub';
import { EOnboardingState, IOnboardingState } from '~/types/app/Onboarding';
import { EHubAvailabilityState, IHub } from '~/types/app/Hub';
import { IOnboardingInput } from '~/types/app/Location';
import { DEFAULT_ONBOARDING_INPUT, DEFAULT_ONBOARDING_STATE } from '~/components/onboarding/LocationSelection/provide';
const __sfc_main = {
  name: 'LocationSelectionHub'
};
__sfc_main.props = {
  heading: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    $hubLocator,
    $apiOrders,
    $logger,
    $segmentEvent,
    i18n
  } = useContext();
  const {
    hubAvailabilityState,
    locationSelectionState
  } = inject<IOnboardingState>('onboardingState', DEFAULT_ONBOARDING_STATE);
  const {
    coordinates,
    hub,
    deliveryEta
  } = inject<IOnboardingInput>('onboardingInput', DEFAULT_ONBOARDING_INPUT);
  onMounted(handleInit);
  watch(locationSelectionState, handleInit);

  async function handleInit(): Promise<void> {
    if (locationSelectionState.value !== EOnboardingState.HubAvailabilityCheck || !coordinates.value) {
      return;
    }

    try {
      hub.value = await getHub($hubLocator, $logger, coordinates.value);

      if (hub.value) {
        await updateDeliveryEta(hub.value);
      }

      if (hub.value && !hub.value?.is_default_hub) {
        updateState(EHubAvailabilityState.success);
      } else {
        updateState(EHubAvailabilityState.failure);
      }
    } catch (e) {
      updateState(EHubAvailabilityState.failure);
    }
  }

  function updateState(state: EHubAvailabilityState): void {
    hubAvailabilityState.value = state;

    if (state !== EHubAvailabilityState.success) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.screenViewed,
        screenName: EScreenName.outsideDeliveryArea,
        componentContent: `${coordinates.value?.latitude}|${coordinates.value?.longitude}`
      });
      return;
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.screenViewed,
      screenName: EScreenName.insideDeliveryArea,
      componentContent: `${coordinates.value?.latitude}|${coordinates.value?.longitude}`
    });
  }

  async function updateDeliveryEta(hub: IHub) {
    if (!hub.coordinates || !coordinates.value) {
      return;
    }

    deliveryEta.value = await getDeliveryEstimate($apiOrders, $logger, {
      deliveryCoordinates: coordinates.value,
      locale: i18n.locale,
      hubSlug: hub.slug
    });
  }

  return {
    EHubAvailabilityState,
    emit,
    hubAvailabilityState
  };
};

export default __sfc_main;
