








































import { ref } from '@nuxtjs/composition-api';
import { defineEmits } from '@vue/runtime-dom';
import { emailValidation } from '~/lib/helpers';
import VTextField from '~/components/VTextField/VTextField.vue';
import { ROUTES } from '~/lib/routes';
const __sfc_main = {
  name: 'WaitlistSignupForm'
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const email = ref('');
  const emailRef = ref<InstanceType<typeof VTextField> | null>(null);
  const {
    TERMS
  } = ROUTES;

  function onJoinWaitlist(): void {
    if (emailRef.value?.validateInput()) {
      emit('waitlist-signup', email.value);
    }
  }

  return {
    emailValidation,
    emit,
    email,
    emailRef,
    TERMS,
    onJoinWaitlist
  };
};

__sfc_main.components = Object.assign({
  VTextField
}, __sfc_main.components);
export default __sfc_main;
